import CartService from "../../services/cart.service";

const cartModule = {
  namespaced: true,
  state: {
    loading: false,
    checkout: null,
    oils: null,
    successAddMessage: 'Товар успешно добавлен в корзину',
    creditData: '',
    isPromoApply: false,
    isAdmin: false,
    panelMessages: {},
    promocodeErrors: {},
  },
  getters: {
    total(state) {
      let total = 0;
      if (state.checkout) {
        total = parseFloat(state.checkout.total);
      }
      return total;
    },
    itemsPrice(state) {
      if (state.checkout && state.checkout.items_price) {
        return parseFloat(state.checkout.items_price);
      }

      return 0
    },
    fullPrice(state) {
      if (state.checkout && state.checkout.items_full_price) {
        return parseFloat(state.checkout.items_full_price);
      }

      return 0
    },
    deliveryPrice(state) {
      if (state.checkout && state.checkout.delivery_price) {
        return parseFloat(state.checkout.delivery_price);
      }

      return 0
    },
    count(state) {
      let count = 0;
      if (state.checkout && state.checkout.items) {
        count = state.checkout.items.length;
      }
      return count;
    },
    totalCount(state) {
      let count = 0;
      if (state.checkout && state.checkout.items) {
        state.checkout.items.forEach((item) => {
          count += item.quantity;
        })
      }

      return count;
    },

    getPaymentData(state) {
      const items = state.checkout.items;
      let data = 'demoFlow=sms&'
      Object.entries(items).forEach(entire => {
        const [key, item] = entire;
        const producerName = item.buyable.product.model.producer.name;
        const modelName = item.buyable.product.model.name;
        const productName = item.buyable.product.name;
        const fullName = producerName + ' ' + modelName + ' ' + productName + '&';
        data = data + `items.${key}.name=` + fullName;
        data = data + `items.${key}.price=` + item.buyable.appraisedPrice + '&';
        data = data + `items.${key}.quantity=` + item.quantity + '&';
      });

      data = data + 'sum=' + state.checkout.items_price + '&';
      data = data + 'orderNumber=' + 1;

      state.creditData = data;

      return data;
    },

    promocodeHasErrors(state) {
      return Object.keys(state.promocodeErrors).length !== 0;
    }
  },
  actions: {
    async loadCheckout({ state, dispatch, commit }) {
      if (!state.checkout && !state.loading) {
        commit('setLoading', true);
        await dispatch('fetchCheckout')
      }
    },
    async loadOils({ state, dispatch, commit }) {
      if (!state.oils) {
        await dispatch('fetchOils')
        commit('setLoading', false);
      }
    },
    async applyPromo( { dispatch, commit }, { code, productIds }) {
      try {
        const result = await CartService.applyPromo(code);
        if (result.data.showError) {
          commit('setPromocodeErrors', result.data);
        } else {
          commit('setCheckout', result.data);
          commit('setPromoApply', true)
          window.goalManager.proceedYandexGoal('promocode_success');
          window.goalManager.proceedGoogleGoal('promocode#success');
          window.goalManager.proceedVkGoal('promocode_success/' + productIds);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async fetchCheckout({ commit }) {
      try {
        const result = await CartService.fetchCheckout();
        commit('setCheckout', result.data);
      } catch (e) {
        console.log(e);
      }
    },
    async fetchOils({ commit }) {
      try {
        const result = await CartService.fetchOils();
        commit('setOils', result.data);
      } catch (e) {
        console.log(e);
      }
    },
    async getIsAdmin({ commit }) {
      try {
        const result = await CartService.getIsAdmin();
        commit('setIsAdmin', result.data);
        return result.data;
      } catch (e) {
        console.log(e);
      }
    },
    async add({ state, dispatch, commit }, { buyable, id, quantity }) {
      try {
        const result = await CartService.add(buyable, id, quantity);
        dispatch('fetchCheckout');
        if (result.data.showError) {
          dispatch('showError', result.data);
        } else {
          dispatch('notification/showSuccessNotification', state.successAddMessage, {root:true});
        }
      } catch (e) {
        dispatch('showError', { message: 'Произошла ошибка' });
      }
    },
    async remove({ state, dispatch, commit }, { key }) {
      try {
        const result = await CartService.remove(key);
        dispatch('fetchCheckout');
        if (result.data.showError) {
          dispatch('showError', result.data);
        }
      } catch (e) {
        dispatch('showError', { message: 'Произошла ошибка' });
      }
    },
    async increase({ state, dispatch, commit }, { key, amount }) {
      try {
        const result = await CartService.increase(key, amount);
        dispatch('fetchCheckout');
        if (result.data.showError) {
          dispatch('showError', result.data);
        }
      } catch (e) {
        dispatch('showError', { message: 'Произошла ошибка' });
      }
    },
    async decrease({ state, dispatch, commit }, { key, amount }) {
      try {
        const result = await CartService.decrease(key, amount);
        dispatch('fetchCheckout');
        if (result.data.showError) {
          dispatch('showError', result.data);
        }
      } catch (e) {
        dispatch('showError', { message: 'Произошла ошибка' });
      }
    },
    showError({ dispatch, commit }, { message, type, key }) {
      if (!type) {
        dispatch('notification/showErrorNotification', message, {root:true});
      } else if (type === 'panel' && key) {
        commit('addPanelMessage', { key, message });
        setTimeout(() => {
          commit('removePanelMessage', key);
        }, 4000);
      }
    },
    showSuccess({ dispatch, commit }, { message, type, key }) {
      if (!type) {
        dispatch('notification/showSuccessNotification', message, {root:true});
      } else if (type === 'panel' && key) {
        commit('addPanelMessage', { key, message });
        setTimeout(() => {
          commit('removePanelMessage', key);
        }, 4000);
      }
    },
    orderGoals({ state }, isFast) {
      let items = state.checkout.items;
      let offersId = [];

      items.forEach((item) => {
        let product = item.buyable.product;
        let offerId = product.outerId;
        offersId.push(offerId)
      })

      window.goalManager.proceedGoal('yandex', 'success_all_lid')
      window.goalManager.proceedGoal('vk', 'success_all/' + offersId);
      if (!isFast) {
        window.goalManager.proceedGoal('yandex', 'success_basket')
        window.goalManager.proceedGoal('vk', 'success_basket/' + offersId)
        window.goalManager.proceedGoal('google', 'cart#success')
      } else {
        window.goalManager.proceedGoal('vk', 'success_quick_order_basket/' + offersId)
        window.goalManager.proceedGoal('yandex', 'success_quick_order_basket')
        window.goalManager.proceedGoal('google', 'order_basket#success')
      }
    }

  },
  mutations: {
    setCheckout(state, data) {
      state.checkout = data;
    },
    setOils(state, data) {
      state.oils = data;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    addPanelMessage(state, { key, message }) {
      state.panelMessages[key] = message;
    },
    removePanelMessage(state, key) {
      state.panelMessages[key] = null;
    },
    setPromocodeErrors(state, errors) {
      state.promocodeErrors = errors;
    },
    setPromoApply(state, data) {
      state.isPromoApply = data;
    },
    setIsAdmin(state, data) {
      state.isAdmin = data;
    }
  },
};

export default cartModule;