<template>
  <div class="cart-order-block cart-order-block_pay">
    <div class="cart-order-block__head">
      <div class="cart-order-block__number-wrap">
        <div class="cart-order-block__number">
          03
        </div>
      </div>
      <h3 class="cart-order-block__title">
        Способ оплаты
      </h3>
    </div>

    <div class="cart-order-block__content form-field">
      <ItemErrors :errors="errors.payment"/>
      <ul class="cart-order-block__radio-list">
        <li class="cart-order-block__radio-item">
          <div class="cart-order-block__radio">
            <input type="radio" name="payment" v-model.number="payment" id="order_cash" value="10">
            <label for="order_cash" class="cart-order-block__input-label">
              <span class="cart-order-block__label-title  text text__main text__main_medium">
                Наличные средства
              </span>
              <span class="cart-order-block__label-description text text__small">
                Оплата производится наличными при получении товаров или услуг
              </span>
            </label>
          </div>
        </li>
        <li class="cart-order-block__radio-item">
          <div class="cart-order-block__radio">
            <input type="radio" name="payment" v-model.number="payment" id="order_transfer" value="20">
            <label for="order_transfer" class="cart-order-block__input-label">
              <span class="cart-order-block__label-title  text text__main text__main_medium">
                Перевод с карты на карту
              </span>
              <span class="cart-order-block__label-description text text__small">
                Оплата производится с помощью перевода с карты на карту после завершения оформления заказа
              </span>
            </label>
          </div>
        </li>
        <li class="cart-order-block__radio-item" v-if="isPhysical">
          <div class="cart-order-block__radio">
            <input type="radio" name="payment" v-model.number="payment" id="order_credit" value="30">
            <label for="order_credit" class="cart-order-block__input-label">
              <span class="cart-order-block__label-title  text text__main text__main_medium">
                Оформление кредита
              </span>
              <span class="cart-order-block__label-description text text__small">
                Оформление кредита в одном из банков-партнеров
              </span>
            </label>
          </div>
        </li>
        <li class="cart-order-block__radio-item" v-if="isShared() === true">
          <div class="cart-order-block__radio">
            <input type="radio" name="payment" v-model.number="payment" id="order_shares" value="40" v-bind="{disabled: isSharedDisabled()}">
            <label for="order_shares" class="cart-order-block__input-label">
              <span class="cart-order-block__label-title text text__main text__main_medium">
                Оплата Долями

                <span class="cart-order-block__help-wrap">
                  <PaymentHelp text="Данный вид оплаты доступен при сумме заказа не превышающей 66 600руб."/>
                </span>
              </span>
              <span class="cart-order-block__label-description text text__small">
                Покупайте сейчас, платите потом - разделите сумму покупки на части
              </span>
            </label>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import ItemErrors from "./ItemErrors.vue";
import PaymentHelp from "../parts/common/PaymentHelp.vue";

export default {
  name: "TheCartOrderPay",
  components: {
    ItemErrors,
    PaymentHelp,
  },
  computed: {
    ...mapState('order', {
      'paymentHui': 'payment',
    }),
    ...mapState('cart', {
      'checkout': 'checkout',
      'isAdmin': 'isAdmin'
    }),
    ...mapGetters('order', {
      'isPhysical': 'isPhysical',
      'isLegal': 'isLegal',
    }),
    payment: {
      get () {
        let  payment = this.$store.state.order.payment;
        if (this.isSharedDisabled() && payment === 40) {
          payment = 10;
          this.$store.commit('order/setPayment', 10)
        }

        return payment;
      },
      set (value) {
        this.$store.commit('order/setPayment', parseInt(value, 10))
      }
    },
    errors: {
      get() {
        return this.$store.state.order.errors
      }
    }
  },
  methods: {
    isShared() {
      return this.checkout.items.every(item => item.buyable.isShared);
    },
    isSharedDisabled() {
      return this.checkout.items_price > 66000
    }
  },
}
</script>

<style lang="scss">

</style>