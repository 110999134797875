import { onDomReady } from '../../components/dynamic/observer';
import LiveEvent from "../../components/live/live";

onDomReady(() => {
  new LiveEvent('click', '[data-yclients-opener]', function openYclients(e) {
    e.preventDefault();
    const yclientsWidget = document.querySelector('a.yButton');
    if (yclientsWidget) {
      yclientsWidget.dispatchEvent(new Event('click'));
    }
  });
});