import {onDomChanges, onDomReady} from "../../components/dynamic/observer";
import {singleBind} from "../../components/dynamic/bind";

function toggleToFavorites(e) {
    const button = this;
    const likes = document.querySelectorAll('[data-id]')
    console.log(button.dataset.id)

    if (!button.dataset.selected) {

        likes.forEach((item) => {
            if(item.dataset.id === button.dataset.id){
                item.classList.add('to-favorite_selected');
            }
        })


        document.dispatchEvent(new CustomEvent('favoritesAdd', {
            detail: {
                chosen: button.dataset.chosen,
                id: parseInt(button.dataset.id),
                outerId: button.dataset.outerId,
            }
        }));
    } else {

        likes.forEach((item) => {
            if(item.dataset.id === button.dataset.id){
                item.classList.remove('to-favorite_selected');
            }
        })

        let removingItem = document.querySelector(`[data-selected="${button.dataset.selected}"]`);
        if (removingItem.closest('.favorites-page__item')) {
            removingItem.closest('.favorites-page__item').remove();
        }

        document.dispatchEvent(new CustomEvent('favoritesRemove', {
            detail: {
                key: button.dataset.selected
            }
        }));
    }
}

onDomChanges(() => {
    document.querySelectorAll('[data-favorites-toggle]').forEach((link) => {
        singleBind(link, 'initialized', 'click', toggleToFavorites);
    });
});