import OrderService from '../../services/order.service';

const orderModule = {
  namespaced: true,
  state: {
    name: '',
    phone: '',
    email: '',
    organization: '',
    clientType: 10,
    receiving: 10,
    payment: 10,
    comment: '',
    address: '',
    pickupPoint: null,
    errors: {
      name: [],
      phone: [],
      email: [],
      addressFilled: [],
      pickupPointSelected: [],
    },
    isPromoApply: false,
    redirectBaseUrl: '/cart/success',
    redirectCreditUrl: '/cart/credit',
    redirectShareUrl: '/cart/share'
  },
  getters: {
    isPickup(state) {
      return state.receiving === 10;
    },
    isDelivery(state) {
      return state.receiving === 20;
    },
    isPhysical(state) {
      return state.clientType === 10;
    },
    isLegal(state) {
      return state.clientType === 20;
    }
  },
  actions: {
    async send({ state, dispatch, commit }, isFast) {
      try {
        const result = isFast ? await OrderService.fastSave(state) : await OrderService.save(state);
        // console.log(result);
        if (result.data.errors) {
          commit('setErrors', result.data.errors)
        } else if (result.data.payment === 30) {
          dispatch('redirectToCreditPage', result)
        } else if (result.data.payment === 40) {
          dispatch('redirectToSharePage', result)
        } else {
          dispatch('cart/orderGoals', isFast, {root:true});
          dispatch('redirectToSuccessPage', result);
        }
        return true;
      } catch (error) {
        dispatch('notification/showErrorNotification', 'Произошла ошибка', {root:true});
      }
      return false;
    },
    redirectToSuccessPage({state, dispatch, commit}, result) {
      const orderFromBasket = 20;
      window.location.pathname = `${state.redirectBaseUrl}/${result.data.order_id}/${orderFromBasket}`;
    },
    redirectToCreditPage({state, dispatch, commit}, result) {
      window.location.pathname = `${state.redirectCreditUrl}/${result.data.order_id}`;
    },
    redirectToSharePage({state, dispatch, commit}, result) {
      window.location.pathname = `${state.redirectShareUrl}/${result.data.order_id}`;
    }
  },
  mutations: {
    setReceiving(state, value) {
      state.receiving = value;
    },
    setPayment(state, value) {
      state.payment = value;
    },
    setClientType(state, value) {
      state.clientType = value;
    },
    setName(state, value) {
      state.name = value;
    },
    setPhone(state, value) {
      state.phone = value;
    },
    setEmail(state, value) {
      state.email = value;
    },
    setAddress(state, value) {
      state.address = value;
    },
    setOrganization(state, value) {
      state.organization = value;
    },
    setPickupPoint(state, value) {
      state.pickupPoint = value;
    },
    setComment(state, value) {
      state.comment = value;
    },
    setErrors(state, value) {
      state.errors = value
    },
    setIsPromoApply(state, value) {
      state.isPromoApply = value
    }
  },
};

export default orderModule;