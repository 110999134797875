<template>
  <div class="cart-page__vue">
    <div class="cart-page__content" v-if="count">
      <div class="cart-page__products">
        <TheCartPositions/>
        <div v-if="this.isShowOils() === true" class="cart-page__add-products">
          <TheCartOils/>
        </div>
      </div>

      <div class="cart-page__result">
        <TheCartResult/>
      </div>

      <div class="cart-page__order">
        <TheCartOrderContacts/>
        <TheCartOrderReceiving/>
        <TheCartOrderPay/>
        <TheCartOrderComment/>

        <div class="cart-page__button-wrap">
          <div class="cart-page__politic text">
            Подтверждая заказ вы соглашаетесь с
            <a href="#" class="cart-page__politic-link">
              политикой конфиденциальности
            </a>
          </div>

          <button class="cart-page__button button" @click="sendOrder" ref="submit">
            Оформить заказ
          </button>
        </div>
      </div>
    </div>
    <div class="cart-page__empty" v-else>
      <div class="cart-page__empty-content">
        <h3 class="cart-page__empty-title">
          Ваша корзина пуста
        </h3>

        <div class="cart-page__empty-subtitle text text__main">
          Добавьте товары из каталога
        </div>

        <a href="/catalog/tyres" class="cart-page__empty-button button">
          в каталог
        </a>

        <div class="cart-page__track">
          <tire-track/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex';
import TheCartPositions from "./TheCartPositions.vue";
import TheCartResult from "./TheCartResult.vue";
import TireTrack from "../icons/TireTrack.vue";
import TheCartOrderContacts from "./TheCartOrderContacts.vue";
import TheCartOrderReceiving from "./TheCartOrderReceiving.vue";
import TheCartOrderPay from "./TheCartOrderPay.vue";
import TheCartOrderComment from "./TheCartOrderComment.vue";
import TheCartOils from "./TheCartOils.vue";

export default {
  name: "TheCartPage",
  components: {
    TheCartOils,
    TheCartPositions,
    TheCartResult,
    TireTrack,
    TheCartOrderContacts,
    TheCartOrderReceiving,
    TheCartOrderPay,
    TheCartOrderComment,
  },
  computed: {
    ...mapState('cart', {
      'checkout': 'checkout',
      'isCartPromoApply': 'isPromoApply'
    }),
    ...mapState('order', {
      'name': 'name',
      'phone': 'phone',
      'clientType': 'clientType',
      'organization': 'organization',
      'errors': 'errors',
      'isOrderPromoApply': "isPromoApply"
    }),
    ...mapGetters('cart', {
      'total': 'total',
      'count': 'count'
    }),
  },
  methods: {
    ...mapActions({
      'loadCheckout': 'cart/loadCheckout',
      'send': 'order/send',
      'fetchProfileInfo': 'profile/fetchInfo'
    }),
    ...mapMutations({
      'setIsPromoApply': 'order/setIsPromoApply',
    }),
    async sendOrder() {
      if (!this.name
          || !this.phone
          || (this.clientType === 20 && !this.organization)
          || (this.receiving === 20 && !this.address)
      ) {
        const success = await this.send(false);
      } else {
        this.$refs.submit.disabled = true;
        if(this.isCartPromoApply) {
          this.setIsPromoApply(this.isCartPromoApply);
        }
        const success = await this.send(false);
      }
    },

    // Проверяем - есть ли масла в списке покупок, показываем блок с маслами если нет
    isShowOils() {
      let isVisible = true;

      this.checkout.items.forEach((item) => {
        const product = item.buyable.product
        if (product && product.categoryName || product.categoryName === 'Моторные масла') {
          isVisible = false
        }
      })

      return isVisible;
    },
  },

  mounted() {
    this.loadCheckout();
    this.fetchProfileInfo();
  }
}
</script>


