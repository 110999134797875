require('../scss/app.scss');
require('./svg/svg.js');

window.noZensmooth = true;
require('../fonts/Inter/stylesheet.css');

require('./common/true-vh');
require('./common/goals2');
require('./common/js-validation');
require('./common/links');
require('./common/main');
require('./common/pagination');
require('./common/respond');
require('./common/map');
require('./common/need-animation');
require('./common/webp-polyfill');
require('./common/ajax-redirect');
require('./common/calltouch');

require('./accordion/initializator');
require('./accordion/switchable-accordion');

require('./field/phone.js');
require('./field/float');
// require('./field/range.js'); // Раскоментить при необходимости
require('../components/smart-tabs/smart-tabs');
require('../components/smart-tabs/smart-tabs-multiply')
require('./header/header.js')

require('./encoder/encoder');
require('./credit');

require('./offices/index');
require('fslightbox');
require('./sliders/index');
require('./parallax/index');
require('./profile/auth/index');
require('./ecommerce/ecommerce');
require('./filters/project-filters');

require('@vueform/slider/themes/default.css');
require('../vue/app.js');
require('./favorites/toggle');

require('./cart/add');
require('./cart/pickupPointMap');

require('./menu-switch/index');
require('./custom-scroll-link/index');

require('./sticky/sticky-head-bottom');
require('./yclients/index');
require('./common/copyToClipboard');