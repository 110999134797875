import {onDomReady} from "../../components/dynamic/observer";
import {trim} from "lodash";

class CopyToClipboard {
    constructor(copyLink) {
        this.link = copyLink;
        this.data = copyLink.dataset.copyLink;
        this.addEvents();
    }

    addEvents() {
        this.link.addEventListener('click', (e) => {
            e.preventDefault();
            this.copyContent();
        });
    }

    async copyContent() {
        const text = this.data;

        try {
            await navigator.clipboard.writeText(trim(text));
            console.log('Content copied to clipboard');
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    }
}

onDomReady(() => {
    document.querySelectorAll('[data-copy-link]').forEach(copyLink => {
        new CopyToClipboard(copyLink);
    })
})